import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import {
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import { ListTLink, CTACard, animateObjects, newContent } from '../../components/Layout';
import SEO from '../../components/seo';
import GiveContentTrans from '../../components/GiveContentTrans';
import Icon from '../../components/Icon';

import MagneticButton from '../../components/MagneticButton';

import LogodesignProcessGIF from '../../assets/CN-logoprotsess.gif';
import Layout from '../../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {



  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
      <SEO
  title="Logo kujundamine – professionaalne logo ja brändiraamatu disain"
  description="Telli unikaalne logo kujundus ja professionaalne brändiraamat, mis eristab sinu ettevõtet. Vaata tehtud töid ja küsi pakkumist!"
  image="/pagecover-teenused.jpg"
  structuredData={{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Service",
        "name": "Logo kujundamine ja brändiraamat",
        "provider": {
          "@type": "Organization",
          "name": "Give Digiagentuur",
          "url": "https://give.ee"
        },
        "areaServed": {
          "@type": "Country",
          "name": "Estonia"
        },
        "serviceType": "Logo kujundamine, brändiraamat ja visuaalne identiteet",
        "description": "Loome kvaliteetseid logosid ja brändiraamatuid, mis aitavad ettevõtetel eristuda ja tugevdada oma brändiidentiteeti.",
        "url": "https://give.ee/teenused/logo-kujundamine/"
      },
      {
        "@type": "LocalBusiness",
        "name": "Give Digiagentuur",
        "url": "https://give.ee",
        "logo": "https://give.ee/give-digital-agency-logo-white-bg.png",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Tallinn",
          "addressCountry": "EE"
        },
        "telephone": "+3725533913",
        "sameAs": [
          "https://www.linkedin.com/company/give-digital-agency/"
        ]
      },
      {
        "@type": "CreativeWork",
        "name": "Logo kujundamise portfoolio",
        "creator": {
          "@type": "Organization",
          "name": "Give Digiagentuur",
          "url": "https://give.ee"
        },
        "about": "Professionaalne logo kujundamine ja brändiraamatud ettevõtetele.",
        "url": "https://give.ee/teenused/logo-kujundamine/",
        "image": "https://give.ee/pagecover-teenused.jpg"
      },
      {
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "Mul on logo olemas, kuid vajan kodulehte ja disainilahendusi?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Kui logo on juba olemas, saame aidata nii kodulehe kujundamisega kui ka erinevate graafilise disaini töödega. Kui logo on väga vana, saame aidata selle kaasajastamise ja brändiraamatu disainiga."
            }
          },
          {
            "@type": "Question",
            "name": "Kui palju maksab logo kujundamine?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Logo kujunduse hind sõltub brändi keerukusest ja projektist. Minimaalselt peaks arvestama 1200€+km. Logo kujundamine on investeering pikkadeks aastateks."
            }
          },
          {
            "@type": "Question",
            "name": "Kuidas käib firma logo kujundamine?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Kõik algab brändi analüüsist ja kliendi soovide kaardistamisest. Loome esmalt 3-5 logo kavandit ning arendame neid edasi vastavalt tagasisidele, kuni leitakse lõplik logo lahendus."
            }
          },
          {
            "@type": "Question",
            "name": "Mis formaadis te logo failid saadate?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Lõplikud logod saadame trükiks PDF-formaadis ja digikasutuseks SVG, PNG või JPG failidena. Vektorvormingud võimaldavad logo skaleerimist ilma kvaliteedi kadumiseta."
            }
          },
          {
            "@type": "Question",
            "name": "Miks ettevõtte bränding on oluline?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Bränding on ettevõtte nägu. Halb või ebaprofessionaalne disain võib vähendada usaldusväärsust. Hea logo ja brändiraamat aitavad ettevõttel eristuda ja kasvatada kliendilojaalsust."
            }
          },
          {
            "@type": "Question",
            "name": "Kui kaua võtab logo tegemine aega?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Logo kujundamise kestus sõltub keerukusest. Lihtsam logo võib valmida 1-2 nädalaga, brändiraamat ja täiendav identiteet võivad võtta 3-6 nädalat."
            }
          },
          {
            "@type": "Question",
            "name": "Mis logo kujundamise programme te kasutate?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Kasutame Adobe Creative Cloud'i ja kujundame logod Adobe Illustratoris."
            }
          },
          {
            "@type": "Question",
            "name": "Soovitage tasuta logo kujundamise generaator?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Meie ei kasuta tasuta logo generaatoreid, kuna loome kõik logod käsitööna. Kui soovite kindlat ja unikaalset logo, on käsitöödisain alati parem lahendus."
            }
          },
          {
            "@type": "Question",
            "name": "Milline on hea logo kujundus?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Hea logo on lihtne, meeldejääv ja selge. Logo peaks olema kasutatav erinevates suurustes ja formaatides. Loe rohkem meie artiklist 'Hea logo disain'."
            }
          }
        ]
      }
    ]
  }}
/>

      </GiveContentTrans>
      <GiveContentTrans language="en">
      <SEO
  title="Logo Design and Brandbook Creation"
  description="Transform your brand with expert design thinking. From creating new brands to revitalizing existing ones, we help you make an impact in today's market."
  image="/pagecover-en-default.jpg"
  structuredData={{
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Service",
        "name": "Logo Design and Brand Identity",
        "provider": {
          "@type": "Organization",
          "name": "Give Digital Agency",
          "url": "https://give.ee"
        },
        "areaServed": {
          "@type": "Country",
          "name": "Estonia"
        },
        "serviceType": "Logo design, brandbook creation, and corporate identity",
        "description": "We create high-quality logos and brand identity guidelines that help businesses stand out and build strong brand recognition.",
        "url": "https://give.ee/en/services/logo-design/"
      },
      {
        "@type": "LocalBusiness",
        "name": "Give Digital Agency",
        "url": "https://give.ee",
        "logo": "https://give.ee/give-digital-agency-logo-white-bg.png",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Tallinn",
          "addressCountry": "EE"
        },
        "telephone": "+3725533913",
        "sameAs": [
          "https://www.linkedin.com/company/give-digital-agency/"
        ]
      },
      {
        "@type": "CreativeWork",
        "name": "Logo Design Portfolio",
        "creator": {
          "@type": "Organization",
          "name": "Give Digital Agency",
          "url": "https://give.ee"
        },
        "about": "Professional logo design and brand identity creation for businesses.",
        "url": "https://give.ee/en/services/logo-design/",
        "image": "https://give.ee/pagecover-en-default.jpg"
      }
    ]
  }}
/>

      </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
        <h1>Logo kujundamine. Kvaliteetne logo ja firmastiil teie ettevõttele</h1>

        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Logo design and corporate visual&nbsp;identity</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
          <p>
  Professionaalne logo kujundus eristab teie ettevõtet konkurentidest ja loob usaldust. Meie loodud logod on selged, meeldejäävad ja kasutatavad nii digis kui trükis.
</p>


            <a href="https://cal.com/tanels/give-free-calls" className="call-cta">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Arutame sinu logo kujundust"
      magneticDistance="150"
      magneticSpeed="0.4"
    />
  </a>
          </GiveContentTrans>
          <GiveContentTrans language="en">
          <p>
  We design logos and brand books. We believe that the logos designed at our digital agency offer the best price-quality ratio in Estonia and are crafted with care. You get a quality logo without the overhead of a creative director. You receive a logo that represents your company professionally.
</p>

<a href="https://cal.com/tanels/give-free-calls" className="call-cta">
    <MagneticButton
      addionalClasses="cta cta-xl"
      buttonLabel="Lets Talk?"
      magneticDistance="150"
      magneticSpeed="0.4"
    />
  </a>
          </GiveContentTrans>
        </div>
      </section>


      <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h2>Meie kujundatud logod</h2>
          </div>

        <div className="content-row has-media">

        <div className="logo-grid">

            <div className="logo-grid-item">
  
            <StaticImage
                  src="../../assets/Trumo-logo-kujundus.png"
                  alt="Fintech ettevõtte logo kujundamine – professionaalne logo disain"
                  layout="fullWidth"
                  title="Fintech logo kujundamine"
                />
            </div>
            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Morphin-logo-kujundus.png"
                  alt="Sotsiaalmeedia sisu genereerimise iOS appi logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Profister-logo-kujundus.png"
                  alt="Autokeemia ettevõtte logo kujundus – professionaalne logo disain"
                  layout="fullWidth"
                  title="logo kujundus"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/ReadyPlayerMe-logo-kujundus.png"
                  alt="Virtuaalsete avataride loomise startupi logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Insero-logo-kujundus.png"
                  alt="Inseneeria ettevõtte logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/BirdBusters-logo-kujundus.png"
                  alt="Linnupeletajate e-poe logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Arilaen-logo-kujundus.png"
                  alt="Finants ettevõtte logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Karat-logo-kujundus.png"
                  alt="Krüptoraha ettevõtte logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/BookedIT-logo-kujundus.png"
                  alt="Online piletimüügi ja meelelahutus asutuste haldamise platvormi logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />



            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/DoorProf-logo-kujundus.png"
                  alt="Doorprof logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/StayBlond-logo-kujundus.png"
                  alt="StayBlond logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Fei-logo-kujundus.png"
                  alt="Fei logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/BlueMemphis-logo-kujundus.png"
                  alt="Bluememph1s logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/b2bgrowth-logo-kujundus.png"
                  alt="B2BGrowth logo"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/EvolvedComputers-logo-kujundus.png"
                  alt="Doorprof logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/developmentacademy-logo-kujundus.png"
                  alt="DevelopmentAcademy logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Bautec-logo-kujundus.png"
                  alt="Bautec logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Kompass-logo-kujundus.png"
                  alt="Kompass restoran logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Podquest-logo-kujundus.png"
                  alt="Podquest logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Arvuta-logo-kujundus.png"
                  alt="Arvuta logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Vanaauto-logo-kujundus.png"
                  alt="Vanaauto logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Rahapalvelu-logo-kujundus.png"
                  alt="Rahapalvelu logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/WV-logo-kujundus.png"
                  alt="WV logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/FutureForm-logo-kujundus.png"
                  alt="FutureForm logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/BuildOptimize-logo-kujundus.png"
                  alt="Build&Optimize logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

  


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/PHT-logo-kujundus.png"
                  alt="PHT logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Kilva-logo-kujundus.png"
                  alt="Kilva logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Nordeko-logo-kujundus.png"
                  alt="Nordeko logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Creditea-logo-kujundus.png"
                  alt="Creditea logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Eikiusamisele-logo-kujundus.png"
                  alt="Ei kiusamisele logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Keldrikoda-logo-kujundus.png"
                  alt="Keldrikoda logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Booleum-logo-kujundus.png"
                  alt="Doorprof logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

  

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/C24sailingteam-logo-kujundus.png"
                  alt="Credit24 Sailing Team logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Kile-logo-kujundus.png"
                  alt="Kile logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Tatoveering-logo-kujundus.png"
                  alt="Tatoveering logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Antman-logo-kujundus.png"
                  alt="Antman logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Kstudio-logo-kujundus.png"
                  alt="Kstudio logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/EstPerformance-logo-kujundus.png"
                  alt="ESTPerformance logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Maatriks-logo-kujundus.png"
                  alt="Maatriks logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Logistics-logo-kujundus.png"
                  alt="Logistics logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/IOConsulting-logo-kujundus.png"
                  alt="IO Consulting logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/BestPress-logo-kujundus.png"
                  alt="BestPress logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/CoinArmy-logo-kujundus.png"
                  alt="Coinarmy logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/myOfficeSupport-logo-kujundus.png"
                  alt="myOfficeSupport logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Techton-logo-kujundus.png"
                  alt="Techton logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>



          </div>
       </div>
       </section>
       </GiveContentTrans>


       <GiveContentTrans language="en">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h2>Logo designs by us</h2>
          </div>

        <div className="content-row has-media">

        <div className="logo-grid">

            <div className="logo-grid-item">
  
            <StaticImage
                  src="../../assets/Trumo-logo-kujundus.png"
                  alt="Fintech company logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>
            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Morphin-logo-kujundus.png"
                  alt="Social iOS application logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Profister-logo-kujundus.png"
                  alt="Automotive chemistry logo design"
                  layout="fullWidth"
                  title="logo design"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/ReadyPlayerMe-logo-kujundus.png"
                  alt="Virtual reality avatar creator logo design"
                  layout="fullWidth"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Insero-logo-kujundus.png"
                  alt="Logo design"
                  layout="fullWidth"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/BirdBusters-logo-kujundus.png"
                  alt="Birdbusters e-commerce logo design"
                  layout="fullWidth"
                  title="logo design"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Arilaen-logo-kujundus.png"
                  alt="Finance logo design"
                  layout="fullWidth"
                  title="logo design"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/Karat-logo-kujundus.png"
                  alt="Crypto company logo design"
                  layout="fullWidth"
                  title="logo design"
                />



            </div>

            <div className="logo-grid-item">
            <StaticImage
                  src="../../assets/BookedIT-logo-kujundus.png"
                  alt="Logo design for online booking and event management platform"
                  layout="fullWidth"
                  title="logo design"
                />



            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/DoorProf-logo-kujundus.png"
                  alt="Doorprof logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/StayBlond-logo-kujundus.png"
                  alt="StayBlond logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Fei-logo-kujundus.png"
                  alt="Fei logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/BlueMemphis-logo-kujundus.png"
                  alt="Bluememph1s logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/b2bgrowth-logo-kujundus.png"
                  alt="B2BGrowth logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/EvolvedComputers-logo-kujundus.png"
                  alt="Doorprof logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/developmentacademy-logo-kujundus.png"
                  alt="DevelopmentAcademy logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Bautec-logo-kujundus.png"
                  alt="Bautec logo kujundus"
                  layout="fullWidth"
                  title="logo kujundus"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Kompass-logo-kujundus.png"
                  alt="Kompass restoran logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Podquest-logo-kujundus.png"
                  alt="Podquest logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Arvuta-logo-kujundus.png"
                  alt="Arvuta logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Vanaauto-logo-kujundus.png"
                  alt="Vanaauto logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Rahapalvelu-logo-kujundus.png"
                  alt="Rahapalvelu logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/WV-logo-kujundus.png"
                  alt="WV logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/FutureForm-logo-kujundus.png"
                  alt="FutureForm logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/BuildOptimize-logo-kujundus.png"
                  alt="Build&Optimize logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Nordeko-logo-kujundus.png"
                  alt="Nordeko logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/PHT-logo-kujundus.png"
                  alt="PHT logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Kilva-logo-kujundus.png"
                  alt="Kilva logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Creditea-logo-kujundus.png"
                  alt="Creditea logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Eikiusamisele-logo-kujundus.png"
                  alt="Ei kiusamisele logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Keldrikoda-logo-kujundus.png"
                  alt="Keldrikoda logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Booleum-logo-kujundus.png"
                  alt="Doorprof logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

  

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/C24sailingteam-logo-kujundus.png"
                  alt="Credit24 Sailing Team logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Kile-logo-kujundus.png"
                  alt="Kile logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Tatoveering-logo-kujundus.png"
                  alt="Tatoveering logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Antman-logo-kujundus.png"
                  alt="Antman logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Kstudio-logo-kujundus.png"
                  alt="Kstudio logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/EstPerformance-logo-kujundus.png"
                  alt="ESTPerformance logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Maatriks-logo-kujundus.png"
                  alt="Maatriks logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Logistics-logo-kujundus.png"
                  alt="Logistics logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/IOConsulting-logo-kujundus.png"
                  alt="IO Consulting logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/BestPress-logo-kujundus.png"
                  alt="BestPress logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/CoinArmy-logo-kujundus.png"
                  alt="Coinarmy logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>

            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/myOfficeSupport-logo-kujundus.png"
                  alt="myOfficeSupport logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>



            <div className="logo-grid-item logo-sm">
            <StaticImage
                  src="../../assets/Techton-logo-kujundus.png"
                  alt="Techton logo design"
                  layout="fullWidth"
                  title="logo design"
                />
            </div>


          </div>
       </div>
       </section>
       </GiveContentTrans>


      <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
            <h2>Ettevõtte logo disainimine</h2>

            <p>
            Hea logo kujunduse retsept koosneb graafilise disaineri maitsest, kogemusest, intuitsioonist
              ja taustainfo kogumisest. Firma logo kujundamine ei ole lihtsalt
              fondiga ühe sõna arvutisse kirjuitamine. Lihtsuses peitub aga võlu aga pisikesed detailid logol loovad lõpptulemuse.
              Teie kliendid märkavad logo kvaliteeti alateadlikult.
            </p>
            <p>
              Logo kujundus peab eristama ettevõtet konkurentidest ehk seal peab
              olema karakterit. Ühevärviline logomärgi kasutusvõimalus on rangelt
              soovituslik. Kujundamisel peab graafiline disaineri arvestama, et logo oleks kasutatav ka võimalikult väikeses
              suuruses. Hea firma logo kujundus on ajatu ning meeldejääv.
            </p>
            <p>
              Tugeva brändi identiteedi disainimisel eelistame klassikalist logo disaini. Sobiva projekti puhul eksperimenteerime graafilise disainiga ning kujundame ka
              erilisemaid firmastiile. Üldjuhul jäävad need kampaania või
              üritus brändingu projektidesse. Teinekord, kui teame, et bränd on
              praktiliselt täies ulatuses digitaalne, lubame endale dünaamilisemat lähenemist.
            </p>
            <p>
             Arutame sinu logo kujundamist ja brändiraamatu loomist? <ListTLink
              to="/kontakt/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >Võta ühendust</ListTLink> ja disainime teile kvaliteetse firmastiili.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>Company logo design</h2>
            <p>
  A good logo design recipe combines a graphic designer's taste, experience, intuition, and background research. Designing a company logo is not just typing a word into a computer with a font. Simplicity holds charm, but the small details in a logo create the final result. Your customers subconsciously notice the quality of the logo.
</p>
<p>
  Logo design must differentiate the company from competitors and have character. A single-color logo version is highly recommended. The graphic designer must ensure that the logo is usable even at very small sizes. A good company logo design is timeless and memorable.
</p>
<p>
  When designing a strong brand identity, we prefer classic logo design. For suitable projects, we experiment with graphic design and create more unique brand styles. Generally, these are for campaign or event branding projects. Sometimes, knowing the brand is almost entirely digital, we allow ourselves a more dynamic approach.
</p>
            <p>
              Need a logo and brand identity design? <ListTLink
              to="/contact/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >Contact us and we design you a new logo</ListTLink>
              
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <div className="img-full">
        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/logo-ja-visiitkaardid.jpg"
            alt="Logo visiitkaartidel"
            layout="fullWidth"
            title="Logo disain visiitkaardil"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/logo-ja-visiitkaardid.jpg"
            alt="Logo in businesscard"
            layout="fullWidth"
            title="Logo design in businesscards"
          />
        </GiveContentTrans>
      </div>

      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Brändiraamatu kujundamine</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Brandbook design</h2>
        </GiveContentTrans>
        <div className="content-row has-media">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
  
              <p>
                Brändiraamatu (ehk korporatiivse visuaalse identiteedi käsiraamatu) eesmärk on panna paika ettevõtte logo ja visuaalse
                identiteedi kujundamise reeglid. Kuidas kasutada teie ettevõtte logo kujundustel ja kuidas disainida brändi turundusmaterjale nii, et need esindaksid firmat parimast küljest. Sõltuvalt brändiraamatu
                mahukusest võib see sisaldada lisaks logo kasutamise reeglitele ja juhistele ka palju muud. 
              </p>

              <p>
                Brändiraamatu ehk CVI (Corporate Visual Identity) kujundamine ei ole üleliia keeruline ja see on kasulik ka väiksematele ettevõtetele, et kindlustada kujunduste ühtne joon ja kvaliteet. 
                Sõltuvalt teie ettevõtte vajadustest saame välja pakkuda kõige optimaalsema sisu. Väiksemas mahus defineerime lihtsalt logo kujundustel kasutamise reeglid, brändi värvid ja kirjatüübid. 
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                Brandbook or CVI or Corporate Visual Identity Manual is the
                document we're talking about. We can also use the term brand
                book or logo usage guidelines. The latter is probably the
                narrowest and most restrictive, but the purpose of the document
                is not to make things more complicated.
              </p>
              <p>
                The purpose of a brand book is to record the rules for using the
                company's visual identity and logo in designs on the best possible way. Depending on the size of the brand
                book, it may also contain a lot more, from the company's values
                to the defined communication tone. In most simple cases brandbook consist of logo usage rules in designs, brand colors and typography. 
              </p>
            </GiveContentTrans>
          </div>
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              {' '}
              <StaticImage
                src="../../assets/logoraamat-varvid.jpg"
                alt="Logoraamatu kujundus, kasutatavad logo värvid"
                layout="fullWidth"
                title="brändiraamat"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/logoraamat-varvid.jpg"
                alt="Brandbook design, color usage"
                layout="fullWidth"
                title="brandbook"
              />
            </GiveContentTrans>
          </div>
        </div>
        <div className="content-row has-media">
          <div className="media content-col-half">
            <GiveContentTrans language="et">
              <StaticImage
                src="../../assets/logoraamat-kirjatüüp.jpg"
                alt="Logoraamatu kujundus, kirjatüübid"
                layout="fullWidth"
                title="brändiraamat"
              />
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <StaticImage
                src="../../assets/logoraamat-kirjatüüp.jpg"
                alt="Brandbook typography"
                layout="fullWidth"
                title="brandbook"
              />
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Minimaalselt võiks brandiraamatus olla logo
                kasutatavad versioonid ja reeglid. Ettevõtte logo kujunduses ja turundusmaterjalides kasutatavad brändi värvid ning
                kirjatüübid. Üldjuhul on välja toodud ka põhiliste
                brändimaterjalide kujundusnäidised. Visiitkaardid, kirjapõhjad,
                reklaamipõhjad, presentatsiooni slaidid, uudiskirja ja veebilehe
                kujundusvaated, sotsiaalmeedia postituste näited ning muu graafiline disain. 
              </p>
              <p>
                Brändiraamatuga koos saadame teile ka erinevad logo kujundus versioonid. Nii saate logo kasutada kõikvõimalikes turundus ja kujundus materjalides ning logo kvaliteet on alati parim.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <p>
                At a minimum, the brand book should include the logo's usable
                versions and rules, the company's colors and fonts, and usually
                the basic design examples of branding materials such as business
                cards, letterheads, advertising templates, presentation slides,
                newsletter and website design views, social media posts, and
                other graphic design.
              </p>
            </GiveContentTrans>
          </div>
        </div>
        <div className="content-row">
          <GiveContentTrans language="et">
            <CTACard
              to="/teenused/graafiline-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Graafiline Disain
            </CTACard>

            <CTACard
              to="/teenused/piiramatult-disaini/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Piiramatu disainiteenus
            </CTACard>


          </GiveContentTrans>
          <GiveContentTrans language="en">
            <CTACard
              to="/services/graphic-design/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
              Graphic Design
            </CTACard>
          </GiveContentTrans>
        </div>
      </section>


      <div className="img-100">

        <GiveContentTrans language="et">
          <StaticImage
            src="../../assets/logoraamat.jpg"
            alt="CVI ehk logoraamat või brändiraamat"
            layout="fullWidth"
            title="logo kujundamine ja brändiraamat"
          />
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <StaticImage
            src="../../assets/logoraamat.jpg"
            alt="CVI or brandbook"
            layout="fullWidth"
            title="brandbook"
          />
        </GiveContentTrans>
      </div>
      


      <GiveContentTrans language="et">
        <section className="description-section inner-grid">

          <div className="section-title">
          <h3>Korduma Kippuvad Küsimused</h3><h2>Logo kujunduse kohta</h2>
          </div>

        <div className="content-row has-media">
        <div id="faq">
  
  <ul>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Mul on logo kujundus olemas aga vajan kodulehte ja erinevaid graafilise disaini lahendusi?</h3>
      <div className="answer">
      <p>Super! Sellisel juhul kui logo kujundus on juba tehtud saame aidata nii <ListTLink
              to="/teenused/kodulehed/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >kodulehe kujundamisega</ListTLink> kui ka erinevate <ListTLink
            to="/teenused/graafiline-disain/"
            exit={{
              length: 1,
              zIndex: 2, // exit default is 0
              trigger: ({ exit, e, node }) => animateObjects(exit, node),
            }}
            entry={{
              delay: 0.5,
              length: 0.6,
              trigger: ({ entry, node }) => newContent(node),
            }}
          >graafilise disaini</ListTLink> töödega.</p>

      <p>Juhul kui teie logo on kujundatud väga pikka aega tagasi saame aidata logo kaasajastamise ning brändiraamatu disainiga.</p>
      </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kui palju maksab logo kujundus?</h3>
      <div className="answer">
      <p>Logo hind sõltub firmastiili projekti keerukusest. Teame, et ümmargune vastus teid ei aita aga kindlat logo kujundamise hinnakiri on keerukas välja tuua. Minimaalselt peaks logo kujundamisel arvestama 1200€+km </p>
      <p>Logo kujundus on investeering pikkadeks aastateks ning usume, et kvaliteetsesse logo disaini on oma hinda väärt. Logo loomisel tasub arvestada sellega, et erinevas staadiumis ettevõtete puhul logo kujundamise protsess erineb. 
        </p>

        <p>
          Logo tellimisel võiksite arvestada ka brändiraamatu disainimisega. Siinkohal on oluliseks hinda kujundavaks teguriks töö maht ja kaasnevad kulud. Kas katsetatakse erinevaid trükitehnoloogiaid ja disainitakse ning toodetakse prototüüpe või on tegu pelgalt digitaalse CVI-ga. 
        </p>
        <p>
          Logo kujundamise hinnale võib lisanduda ka kirjatüüpide litsentsid. Fondid võivad maksta mõnekümmnest eurost kuni kümmnete tuhandeteni. Brändiraamatu koostamisel võivad tekkida seotud kulud fotode produtseerimise või ostmisega. 
          Kõik kaasnevad kulud arutatakse kliendiga kindlasti eelnevalt läbi ja vähemalt Givest logo tellides võite olla kindlad, et üllatusi ei tule. 
        </p>

        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Kuidas käib firma logo kujundamine?</h3>
      <div className="answer">
      <p>Logo kujundamise projekti puhul on tähtis kõigepealt aru saada kes ja millele logo vajab. Millised on logo eesmärgid jne. Eesti ja Tallinna logo disaini klientidega hea meelega kohtume, et asju arutada. Muus osas teeme nö vabas vormis ülevaate videokõne teel. </p>
      <p>Kui näeme, et saaksime antud logo kujundamise projektiga aidata ja meil on ühine arusaam mida on vaja ning oleme kindlad, et suudame disainida suurepärase logo mis lõppkliendile sobib ja meeldib saadame välja hinnapakkumise. 
        </p>
      <p>
        Kõik logo ja firmastiili disaini projektid arveldame Gives 100% ettemaksuga. Ehk siis logo ja firmastiili kujundus töö hakkab peale arve maksmist. Kõigepealt saadame teile küsimustiku, et saada võimalikult täpne ülevaade teie tegevusest, klientidest ja ootustest logole. 
         </p>
         <p>
Kui olete küsimustiku meile tagasi saatnud hakkab logo disainer kujundama. Logo disainimne loov protsess ja üldjuhul eelistame siin ajalisi piiranguid vältida. Muidugi kui on võimalik. Kui teil on kiiresti vaja proffessionaalset logo kujundust 
siis usun, et just Give graafiline disainer suudab tänu oma pikaaegsele kogemusele pakkuda Eesti parimat kiiruse ja kvaliteedi suhet. Ideaalis tuleks logo kujundus projektiga rahulikult toimetada, et saavutada parim lõpptulemus. Kindlasti lepime teiega kokku 
tähtajad millal kujunduskavandeid presenteeritakse ja te ei pea muretsema ära kaduva disainerite pärast.</p>

<p>Üldjuhul kujundame teile esmalt 3-5 erinevat logo kontseptsiooni. 
Pärast esmaste logokavandite esitlemist muutub protsess vabamaks. Enamasti on seal olemas juba võitja logo ning asume seda tagasiside põhjal viimistlema. Võib juhtuda, et esimese korraga ei saa logo kujundust korvi. Sellisel juhul 
arutame kliendiga läbi, et mis esitletud logokavandite juures ei tööta. Meie jaoks ei ole see mitte mahavisatud töö vaid teadmine millised logo disainid teie ettevõttele ei sobi. Ehk väga tugev alus mille põhjalt edasi disainida.</p>

<p>
        Kui sobiv logomärgi kujundus on leitud siis disainime sellest erinevad versioonid nii trükis kui digitaalselt kasutamiseks. Sõltuvalt lõpplikust logo kujundusest võime teha ka eri värvilised lahendused. 
        Logod saadame teile erinevates formaatides, et saaksite neid muretult erinevates veebi ja graafilise disaini projektides kasutada. 
         </p>
        </div>
    </li>
    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
      <h3>Mis formaadis te logo failid saadate?</h3>
      <div className="answer">
      <p>Lõplikud logod saadame trükiste jaoks CMYK värviformaadis .pdf failitüübina. Digitaalsete kasutuskohtade jaoks saadame RGB logo .svg ja .png või .jpg failidena. </p>
      <p>.pdf ja .svg on vektor formaadid ja nende eelis on selles, et logo saab ilma kvaliteedi kadudeta suurendada või vähendada.</p>
      <p>Nendes failiformaatides saate logo kasutada erinevates kujundustes. Juhul kui on kindlaid erisoove muude formaatide osas tasub enne projektiga alustamist täpsustada.</p>
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
    
      <h3>Miks see ettevõtte bränding oluline on?</h3>
      <div className="answer">
      
      <p>Teie ettevõtte bränd on firma nägu. Kui teil on lohakas või kehva disainiga bränd ei mõju teie ettevõte ka usaldusväärselt. Logo on kui ettevõtte näpujälg. Kindlasti ei tasuks logo kujundamisel kasutada valmisgraafikat või 
        vaadata seda kui tarbetut elementi mis tuleb lihtsalt kuidagi ära teha. Hea logo kujundus on investeering pikkadeks aastateks ja esindab teie ettevõtet. 
      </p>


      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>
    
      <h3>Kui kaua võtab logo tegemine aega?</h3>
      <div className="answer">
      
      <p>
  Logo tegemise aeg sõltub keerukusest. Lihtsam logo võib olla valmis 1-2 nädalaga, brändiraamatu ja täiendava identiteediga lahendus võib võtta 3-6 nädalat.
</p>


      </div>
    </li> 

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Mis Logo kujundus programme te kasutate?</h3>
      <div className="answer">
      <p>Kasutame Adobe Creative Cloudis olevat kujundustarkvara. Logod kujundame Adobe Illustraatoris</p>
      </div>
    </li>



    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Soovitage tasuta logo kujundamise generaator?</h3>
      <div className="answer">
      <p>Kahjuks ei oska nõu anda. Give disainer kujundab kõik logod ise ja kogemus tasuta või ka tasuliste logo generaatoritega puudub. Võimalik, et nö logo kujundamise loovkontseptsioonide puhul kasutame moodsaid AI (Artificial Intelligence) lahendusi, et rikastada ideede panka. 
        Kindlasti me ei võta sealt logo kujunduse valmislahendusi. 
      </p>
      <p>Kui otsustate kasutada logo kujundamiseks generaatorit soovitame veenduda, et saaksite logost kindlasti vektor formaadid. Lisaks tasub mõelda kas antud logo kujundus versiooni võivad kasutada sajad või tuhanded ettevõtted lisaks teile.</p>
     
      </div>
    </li>

    <li>
      <input type="checkbox" defaultChecked></input>
      <div className="faq-arrow"><Icon name="arrowleft" /></div>

      <h3>Milline on hea logo kujundus?</h3>
      <div className="answer">
      <p>Hea logo kujundus sõltub ettevõtte vajadustest. Üldiselt võib öelda, et hea logo on lihtne, meeldejääv ja kasutatav erinevatel pindadel ning suurustes. 
      </p>
      <p>Kui soovite lähemalt teada mida meie logo disainer peab heaks logoks saate lugeda artiklit teemal <ListTLink
            to="/artiklid/hea-logo-disain/"
            exit={{
              length: 1,
              zIndex: 2, // exit default is 0
              trigger: ({ exit, e, node }) => animateObjects(exit, node),
            }}
            entry={{
              delay: 0.5,
              length: 0.6,
              trigger: ({ entry, node }) => newContent(node),
            }}
          >Hea logo disain</ListTLink>.</p>
     
      </div>
    </li>

  </ul>
</div>

    </div>

      </section>
      </GiveContentTrans>



      <div className="img-full">
        <GiveContentTrans language="et">

        <img src={LogodesignProcessGIF} alt="Logo kujundamis protsess" title="Logo disaini projekt"></img> 


        </GiveContentTrans>
        <GiveContentTrans language="en">

        <img src={LogodesignProcessGIF} alt="Logo design process" title="Logo design work"></img> 
 
        </GiveContentTrans>
      </div>



      <section className="description-section inner-grid">
        <GiveContentTrans language="et">
          <h2>Logo disain ja brändiraamat on olulised</h2>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h2>Logo design and brandbook are important</h2>
        </GiveContentTrans>

        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <p>
                Logo on brändi osa. Bränd on kliendi
                tunnetus tootest, teenusest või firmast. See on iga kliendi
                jaoks unikaalne. Fakt on, et kliendid suhtuvad heasse disaini
                positiivselt. Hea logo kujundus loob usaldust ja näitab ettevõtte tugevust.

                </p>
                <p>
                Tugeva bränd = usaldusväärsus =  rohkem päringuid = suurem müük. Võta ühendust kui tahad kvaliteetset logo kujundust.
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
            <p>
  A logo is part of a brand. A brand is the customer's perception of a product, service, or company, and it is unique to each customer. The fact is that customers respond positively to good design. A well-designed logo builds trust and showcases the company's strength.
</p>
<p>
  Strong brand = reliability = more inquiries = higher sales. Contact us if you want a quality logo design.
</p>
            </GiveContentTrans>
          </div>
        </div>
      </section>
    </main>
  
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;